import { toast } from "sonner"
import { create } from "zustand"

import {
  apiConsentEmailAtAdminApplicationDraft,
  apiConsentToActRequestAtAdminApplicationDraft,
  apiCreateAtAdminApplicationDraft,
  apiFindLendersAtAdminApplicationDraft,
  apiGetBorrowersAtAdminApplicationDraft,
  apiIdVerifyRequestAtAdminApplicationDraft,
  apiInviteBorrowersAtAdminApplicationDraft,
  apiInviteEmailAtAdminApplicationDraft,
  apiRefreshVerificationAtAdminApplicationDraft,
  apiSubmitAtAdminApplicationDraft,
  apiUpdateAtAdminApplicationDraft,
  apiVerifyEmailAtAdminApplicationDraft
} from "@/api/adminApplicationDraftsApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminApplicationDraftState {
  locked: boolean

  getBorrowersAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void

  findLendersAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (data: any) => void
  ) => void

  createAction: (params: any, onSuccessCallback?: (data: any) => void) => void

  updateAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (data: any) => void
  ) => void

  submitAction: (applicationUuid: string, onSuccessCallback?: () => void) => void

  inviteBorrowersAction: (
    uuid: string,
    borrowerIndividualUuids: string[],
    onSuccessCallback?: (newData: any) => void
  ) => void
  inviteEmailAction: (inviteUuid: string, onSuccessCallback?: () => void) => void

  consentToActRequestAction: (
    applicationUuid: string,
    onSuccessCallback?: (newData: any) => void
  ) => void
  consentEmailAction: (consentUuid: string, onSuccessCallback?: () => void) => void

  idVerifyRequestAction: (
    applicationUuid: string,
    onSuccessCallback?: (newData: any) => void
  ) => void
  verifyEmailAction: (verifyUuid: string, onSuccessCallback?: () => void) => void
  refreshVerificationAction: (
    applicationUuid: string,
    onSuccessCallback?: (newData: any) => void
  ) => void
}

const useAdminApplicationDraftStore = create<IAdminApplicationDraftState>()((set, get) => ({
  locked: false,

  getBorrowersAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiGetBorrowersAtAdminApplicationDraft(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  findLendersAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiFindLendersAtAdminApplicationDraft(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  createAction: async (params, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiCreateAtAdminApplicationDraft(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  updateAction: async (applicationUuid: string, params: any, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiUpdateAtAdminApplicationDraft(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  submitAction: async (applicationUuid, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiSubmitAtAdminApplicationDraft(applicationUuid)

      if (response?.data) {
        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  inviteBorrowersAction: async (uuid, borrowerIndividualUuids, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiInviteBorrowersAtAdminApplicationDraft(uuid, {
        individual_uuids: borrowerIndividualUuids
      })

      if (response?.data) {
        const { message, data } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  inviteEmailAction: async (inviteUuid, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiInviteEmailAtAdminApplicationDraft(inviteUuid)

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  consentToActRequestAction: async (applicationUuid, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiConsentToActRequestAtAdminApplicationDraft(applicationUuid)

      if (response?.data) {
        const { message, data } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  consentEmailAction: async (consentUuid, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiConsentEmailAtAdminApplicationDraft(consentUuid)

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  idVerifyRequestAction: async (applicationUuid, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiIdVerifyRequestAtAdminApplicationDraft(applicationUuid)

      if (response?.data) {
        const { message, data } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  verifyEmailAction: async (verifyUuid, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiVerifyEmailAtAdminApplicationDraft(verifyUuid)

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  refreshVerificationAction: async (applicationUuid, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiRefreshVerificationAtAdminApplicationDraft(applicationUuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  }
}))

export default useAdminApplicationDraftStore
