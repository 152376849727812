import React from "react"

import { InputMask } from "@react-input/mask"
import { useFormContext } from "react-hook-form"

import { AvatarUpload } from "@/components/_uiext"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"

export default function BasicInformation() {
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Basic information</h3>

      <div className="mb-4">
        <FormField
          control={form.control}
          name="image"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-main text-sm font-normal">Image</FormLabel>
              <AvatarUpload
                readOnly
                placeholder={`${form.watch("first_name")?.[0] ?? ""}${form.watch("last_name")?.[0] ?? ""}`}
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
                <Input disabled autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
                <Input disabled autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
                <Input disabled autoComplete="off" type="email" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="mobile_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Mobile number</FormLabel>
                <InputMask
                  showMask
                  component={Input}
                  mask="04__ ___ ___"
                  replacement={{ _: /\d/ }}
                  ref={field.ref}
                  name={field.name}
                  value={field.value || "04__ ___ ___"}
                  onChange={field.onChange}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
