import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { LoadingButton } from "@/components/_uiext"
import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import MultipleSelector, { Option } from "@repo/ui/components/eui/multiple-selector"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  data: any
  setData: Dispatch<SetStateAction<any>>
}

export default function InviteBorrowerDialog(props: Readonly<Props>) {
  const { open, setOpen, data, setData } = props
  const store = useApplicationDraftStore()
  const [options, setOptions] = useState<Option[]>([])
  const [value, setValue] = useState<Option[]>([])

  const handleInviteClick = () => {
    store.inviteBorrowersAction(
      data?.uuid ?? "",
      value.map((v) => v.value),
      (newData) => {
        setData(newData)
        setValue([])
        setOpen(false)
      }
    )
  }

  useEffect(() => {
    if (data?.borrower?.individuals?.length > 0) {
      const invitedIndividuals = data?.invites?.map((i: any) => i.individual_uuid) ?? []
      const tempOptions = data?.borrower?.individuals
        ?.filter((bi: any) => !invitedIndividuals.includes(bi.uuid))
        .map((bi: any) => ({
          label: `${bi.first_name} ${bi.middle_name} ${bi.last_name} - ${bi.email}`,
          value: bi.uuid
        }))
      setOptions(tempOptions)
    }
  }, [data, open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto"
        onOpenAutoFocus={(e: any) => e.preventDefault()}
      >
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col">
            <h1 className="text-main mb-6 text-center text-lg font-semibold">Invite Borrower</h1>

            <div className="text-main mb-2 text-sm font-normal">Enter borrowers email address</div>

            <div className="mb-4">
              <MultipleSelector
                hidePlaceholderWhenSelected
                defaultOptions={options}
                placeholder="Select individuals to be emailed..."
                badgeClassName="text-sm font-normal"
                emptyIndicator={
                  <p className="text-main text-center text-sm">No individuals found.</p>
                }
                value={value}
                onChange={setValue}
              />
            </div>
          </div>

          <div className="flex justify-center">
            <LoadingButton
              type="button"
              disabled={!value.length}
              loading={store.loading}
              onClick={handleInviteClick}
              className="w-full px-6 py-4 md:w-auto"
            >
              Invite
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
