import React from "react"

import { useAdminAppContext } from "@/pages/admin/applications/AdminAppContext"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

import ConstructionFinance from "./ConstructionFinance"
import Default from "./Default"

export default function SecurityDetailsSummary() {
  const { data } = useAdminAppContext()

  if (data?.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key)
    return <ConstructionFinance />

  return <Default />
}
