import React, { useEffect, useState } from "react"

import { InputMask } from "@react-input/mask"
import clsx from "clsx"
import { NumericFormat } from "react-number-format"

import { useAppContext } from "@/pages/applications/AppContext"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { FormControl, FormItem, FormLabel } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  APPLICATION_ASSET_TYPES,
  APPLICATION_LIABILITY_TYPES,
  ENTITY_TYPES,
  TRUST_TYPES,
  YN
} from "@repo/util/constant"

export default function EntityALSummary() {
  const { data } = useAppContext()

  const [entityAssets, setEntityAssets] = useState<any[]>([])
  const [entityLiabilities, setEntityLiabilities] = useState<any[]>([])

  useEffect(() => {
    if (Object.keys(data ?? {}).length) {
      setEntityAssets(
        data?.assets?.filter((a: any) => a.type === APPLICATION_ASSET_TYPES.Entity) ?? []
      )
      setEntityLiabilities(
        data?.liabilities?.filter((l: any) => l.type === APPLICATION_LIABILITY_TYPES.Entity) ?? []
      )
    } else {
      setEntityAssets([])
      setEntityLiabilities([])
    }
  }, [data])

  return (
    <div className="flex w-full flex-col gap-4 rounded-2xl border p-4 md:p-6">
      <Accordion type="multiple" className="w-full" defaultValue={["entity-details"]}>
        <AccordionItem className="mb-4 rounded-2xl border p-4" value="entity-details">
          <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
            <div className="flex items-center justify-between gap-4">
              <h3 className="text-main text-lg font-semibold">Entity Details</h3>
            </div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col p-0">
            <div className="mb-4 mt-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Entity type</FormLabel>
                  <Select disabled value={data?.borrower?.entity_type}>
                    <SelectTrigger
                      className={clsx(
                        "h-12 w-full text-sm",
                        data?.borrower?.entity_type ? "text-main" : "text-default"
                      )}
                    >
                      <SelectValue placeholder="Please select" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                        <SelectItem key={k} value={v.key}>
                          {v.key}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              </div>

              <div className="hidden flex-1 md:block" />
            </div>

            <div
              className={clsx(
                "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                !data?.borrower?.entity_type && "hidden"
              )}
            >
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Entity name</FormLabel>
                  <Input disabled value={data?.borrower?.entity_name ?? ""} />
                </FormItem>
              </div>

              <div className="hidden flex-1 md:block" />
            </div>

            <div
              className={clsx(
                "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                !data?.borrower?.entity_type && "hidden"
              )}
            >
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Australian business number (ABN)
                  </FormLabel>
                  <Input disabled autoComplete="off" value={data?.borrower?.abn ?? ""} />
                </FormItem>
              </div>

              <div className="flex-1">
                <div
                  className={clsx(
                    data?.borrower?.entity_type === ENTITY_TYPES.Company.key ||
                      data?.borrower?.entity_type === ENTITY_TYPES.Trust.key ||
                      data?.borrower?.entity_type === ENTITY_TYPES.SMSF.key
                      ? "block"
                      : "hidden"
                  )}
                >
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">
                      Australian company number (ACN)
                    </FormLabel>
                    <Input disabled autoComplete="off" value={data?.borrower?.acn ?? ""} />
                  </FormItem>
                </div>
              </div>
            </div>

            <div
              className={clsx(
                "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                (!data?.borrower?.entity_type ||
                  data?.borrower?.entity_type !== ENTITY_TYPES.Trust.key) &&
                  "hidden"
              )}
            >
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Type of trust</FormLabel>
                  <Select disabled value={data?.borrower?.trust_type}>
                    <SelectTrigger
                      className={clsx(
                        "h-12 w-full text-sm",
                        data?.borrower?.trust_type ? "text-main" : "text-default"
                      )}
                    >
                      <SelectValue placeholder="Please select" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.entries(TRUST_TYPES).map(([k, v]) => (
                        <SelectItem key={k} value={v.key}>
                          {v.key}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              </div>

              <div className="flex-1">
                <div
                  className={clsx(
                    data?.borrower?.trust_type === TRUST_TYPES.Other.key ? "block" : "hidden"
                  )}
                >
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">
                      Please enter your type of trust
                    </FormLabel>
                    <Input
                      disabled
                      autoComplete="off"
                      value={data?.borrower?.trust_type_other ?? ""}
                    />
                  </FormItem>
                </div>
              </div>
            </div>

            <div
              className={clsx(
                "mb-4 flex w-full flex-col gap-4 md:flex-row md:items-center md:gap-8",
                !data?.borrower?.entity_type && "hidden"
              )}
            >
              <div className="flex-1">
                <FormItem className="flex items-start space-x-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      disabled
                      checked={data?.borrower?.is_gst_registered}
                      className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                    />
                  </FormControl>
                  <div className="flex flex-col">
                    <FormLabel className="text-main text-sm font-normal">GST Registered</FormLabel>
                  </div>
                </FormItem>
              </div>

              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Postal address</FormLabel>
                  <Input disabled autoComplete="off" value={data?.borrower?.postal_address ?? ""} />
                </FormItem>
              </div>
            </div>

            <div
              className={clsx(
                "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                !data?.borrower?.entity_type && "hidden"
              )}
            >
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Principal place of business
                  </FormLabel>
                  <Input
                    disabled
                    autoComplete="off"
                    value={data?.borrower?.principal_place ?? ""}
                  />
                </FormItem>
              </div>

              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Registered place of business
                  </FormLabel>
                  <Input
                    disabled
                    autoComplete="off"
                    value={data?.borrower?.registered_place ?? ""}
                  />
                </FormItem>
              </div>
            </div>

            <div
              className={clsx(
                "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                !data?.borrower?.entity_type && "hidden"
              )}
            >
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Entity email address
                  </FormLabel>
                  <Input
                    disabled
                    type="email"
                    autoComplete="off"
                    value={data?.borrower?.entity_email ?? ""}
                  />
                </FormItem>
              </div>
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Entity contact number
                  </FormLabel>
                  <InputMask
                    showMask
                    disabled
                    component={Input}
                    mask="04__ ___ ___"
                    replacement={{ _: /\d/ }}
                    value={data?.borrower?.entity_contact_number ?? ""}
                  />
                </FormItem>
              </div>
            </div>

            <div
              className={clsx(
                "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                !data?.borrower?.entity_type && "hidden"
              )}
            >
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Primary business activity
                  </FormLabel>
                  <Input
                    disabled
                    autoComplete="off"
                    value={data?.borrower?.primary_business_activity ?? ""}
                  />
                </FormItem>
              </div>
              <div className="flex-1">
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Website</FormLabel>
                  <Input
                    disabled
                    type="url"
                    autoComplete="off"
                    value={data?.borrower?.website ?? ""}
                  />
                </FormItem>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="multiple" className="w-full">
        <AccordionItem className="mb-4 rounded-2xl border p-4" value="assets">
          <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
            <div className="flex w-full items-center justify-between gap-4 pr-4">
              <h3 className="text-main relative text-lg font-semibold">
                Entity Assets
                <div className="absolute right-[-16px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-normal text-white">
                  {entityAssets.length}
                </div>
              </h3>
            </div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-4 p-0">
            {entityAssets.map((item, index) => (
              <div
                key={item.id}
                className={clsx("flex flex-col rounded-xl border p-4", index === 0 && "mt-4")}
              >
                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Description of asset
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.description} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Value of asset
                      </FormLabel>
                      <NumericFormat
                        disabled
                        customInput={Input}
                        thousandSeparator
                        prefix="$"
                        placeholder="$"
                        value={item.value ?? ""}
                      />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Does your asset have an associated debt?
                      </FormLabel>
                      <Select disabled value={item.associated_debt}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            item.associated_debt ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(YN).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>

                  <div className="hidden flex-1 md:block" />
                </div>

                <div
                  className={clsx(
                    "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                    item.associated_debt === YN.Yes ? "block" : "hidden"
                  )}
                >
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Who is the lender?
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.lender} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Monthly repayment / limit
                      </FormLabel>
                      <NumericFormat
                        disabled
                        customInput={Input}
                        thousandSeparator
                        prefix="$"
                        placeholder="$"
                        value={item.monthly_repayment ?? ""}
                      />
                    </FormItem>
                  </div>
                </div>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="multiple" className="w-full">
        <AccordionItem className="mb-4 rounded-2xl border p-4" value="liabilities">
          <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
            <div className="flex w-full items-center justify-between gap-4 pr-4">
              <h3 className="text-main relative text-lg font-semibold">
                Entity Liabilities
                <div className="absolute right-[-16px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-normal text-white">
                  {entityLiabilities.length}
                </div>
              </h3>
            </div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-4 p-0">
            {entityLiabilities.map((item, index) => (
              <div
                key={item.id}
                className={clsx("flex flex-col rounded-xl border p-4", index === 0 && "mt-4")}
              >
                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Description of liability
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.description} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Amount owing</FormLabel>
                      <NumericFormat
                        disabled
                        customInput={Input}
                        thousandSeparator
                        prefix="$"
                        placeholder="$"
                        value={item.amount_owing ?? ""}
                      />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Who is the lender?
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.lender} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Monthly repayment / limit
                      </FormLabel>
                      <NumericFormat
                        disabled
                        customInput={Input}
                        thousandSeparator
                        prefix="$"
                        placeholder="$"
                        value={item.monthly_repayment ?? ""}
                      />
                    </FormItem>
                  </div>
                </div>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
