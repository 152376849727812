import { toast } from "sonner"
import { create } from "zustand"

import {
  apiConsentEmailAtApplicationDraft,
  apiConsentToActRequestAtApplicationDraft,
  apiCreateAtApplicationDraft,
  apiFindLendersAtApplicationDraft,
  apiGetBorrowersAtApplicationDraft,
  apiIdVerifyRequestAtApplicationDraft,
  apiInviteBorrowersAtApplicationDraft,
  apiInviteEmailAtApplicationDraft,
  apiRefreshVerificationAtApplicationDraft,
  apiSubmitAtApplicationDraft,
  apiUpdateAtApplicationDraft,
  apiVerifyEmailAtApplicationDraft
} from "@/api/applicationDraftsApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IApplicationDraftState {
  loading: boolean

  getBorrowersAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void
  findLendersAction: (uuid: string, params: any, onSuccessCallback?: (newData: any) => void) => void

  createAction: (params: any, onSuccessCallback?: (newData: any) => void) => void
  updateAction: (uuid: string, params: any, onSuccessCallback?: (newData: any) => void) => void
  submitAction: (uuid: string, onSuccessCallback?: () => void) => void

  inviteBorrowersAction: (
    applicationUuid: string,
    borrowerIndividualUuids: string[],
    onSuccessCallback?: (newData: any) => void
  ) => void
  inviteEmailAction: (inviteUuid: string, onSuccessCallback?: () => void) => void

  consentToActRequestAction: (
    applicationUuid: string,
    onSuccessCallback?: (newData: any) => void
  ) => void
  consentEmailAction: (consentUuid: string, onSuccessCallback?: () => void) => void

  idVerifyRequestAction: (
    applicationUuid: string,
    onSuccessCallback?: (newData: any) => void
  ) => void
  verifyEmailAction: (verifyUuid: string, onSuccessCallback?: () => void) => void
  refreshVerificationAction: (
    applicationUuid: string,
    onSuccessCallback?: (newData: any) => void
  ) => void
}

const useApplicationDraftStore = create<IApplicationDraftState>()((set, get) => ({
  loading: false,

  getBorrowersAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetBorrowersAtApplicationDraft(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  findLendersAction: async (uuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiFindLendersAtApplicationDraft(uuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  createAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiCreateAtApplicationDraft(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateAction: async (uuid: string, params: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateAtApplicationDraft(uuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  submitAction: async (uuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiSubmitAtApplicationDraft(uuid)

      if (response?.data) {
        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  inviteBorrowersAction: async (applicationUuid, borrowerIndividualUuids, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiInviteBorrowersAtApplicationDraft(applicationUuid, {
        individual_uuids: borrowerIndividualUuids
      })

      if (response?.data) {
        const { message, data } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  inviteEmailAction: async (inviteUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiInviteEmailAtApplicationDraft(inviteUuid)

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  consentToActRequestAction: async (applicationUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiConsentToActRequestAtApplicationDraft(applicationUuid)

      if (response?.data) {
        const { message, data } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  consentEmailAction: async (consentUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiConsentEmailAtApplicationDraft(consentUuid)

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  idVerifyRequestAction: async (applicationUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiIdVerifyRequestAtApplicationDraft(applicationUuid)

      if (response?.data) {
        const { message, data } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyEmailAction: async (verifyUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiVerifyEmailAtApplicationDraft(verifyUuid)

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  refreshVerificationAction: async (applicationUuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiRefreshVerificationAtApplicationDraft(applicationUuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useApplicationDraftStore
