import React from "react"

import { InputMask } from "@react-input/mask"
import { useFormContext } from "react-hook-form"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"

interface Props {
  isView?: boolean
}

export default function MainContactPerson(props: Readonly<Props>) {
  const { isView = false } = props
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Main Contact Person</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="mcp_first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
                <Input disabled={isView} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="mcp_middle_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Middle name</FormLabel>
                <Input disabled={isView} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="mcp_last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
                <Input disabled={isView} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="mcp_email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
                <Input disabled={isView} type="email" autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="mcp_mobile_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Mobile number</FormLabel>
                <InputMask
                  showMask
                  disabled={isView}
                  component={Input}
                  mask="04__ ___ ___"
                  replacement={{ _: /\d/ }}
                  ref={field.ref}
                  name={field.name}
                  value={field.value || "04__ ___ ___"}
                  onChange={field.onChange}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="mcp_position"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Position</FormLabel>
                <Input disabled={isView} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="hidden flex-1 md:block" />
      </div>
    </div>
  )
}
