const CLIENT_MSG_EN = {
  BORROWER_INDIVIDUALS_LIMITED:
    "You've reached the maximum number of individuals permitted for each borrower.",
  APP_CONSENT_INCOMPLETE: "Consent to act request is incomplete.",
  APP_VERIFICATION_INCOMPLETE: "ID verification is incomplete.",

  SOMETHING_WENT_WRONG:
    "Oops! Something went wrong. We're sorry for the inconvenience. Please try again later, or contact our support team if the problem persists.",

  FIELD_REQUIRED: "This field is required.",
  CHOOSE_ONE_ITEM: "Please choose one item."
}

export default CLIENT_MSG_EN
