import React from "react"

import { APIProvider } from "@vis.gl/react-google-maps"
import { useFormContext } from "react-hook-form"

import { extractPlace } from "@/utils/google"
import { PlacesAutoPredict } from "@/components/_uiext"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"

export default function BusinessAddress() {
  const form = useFormContext()

  const handlePlaceSelect = (address_components: Array<Record<string, any>> | null) => {
    if (address_components) {
      const placeInfo = extractPlace(address_components)

      form.setValue("street_address", placeInfo.street)
      form.setValue("suburb", placeInfo.suburb)
      form.setValue("state", placeInfo.state?.long_name)
      form.setValue("postcode", placeInfo.postcode)
    }
  }

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Business Address</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="street_address"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Street address</FormLabel>
                <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                  <PlacesAutoPredict textValue={field.value} onPlaceSelect={handlePlaceSelect} />
                </APIProvider>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="suburb"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="postcode"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
