import React from "react"

import { NumericFormat } from "react-number-format"
import { format } from "date-fns"

import { IconCalendar } from "@/components/_icons"
import { useAppContext } from "@/pages/applications/AppContext"

import { FormControl, FormItem, FormLabel } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { EXIT_STRATEGIES, LOAN_TERMS } from "@repo/util/constant"

export default function LoanDetailsSummary() {
  const { data } = useAppContext()

  return (
    <div className="flex w-full flex-col gap-4 rounded-2xl border p-4 md:p-6">
      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Loan amount</FormLabel>
            <NumericFormat
              disabled
              customInput={Input}
              thousandSeparator
              prefix="$"
              value={data?.loan_amount ?? ""}
            />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Project End Value</FormLabel>
            <NumericFormat
              disabled
              customInput={Input}
              thousandSeparator
              prefix="$"
              value={data?.project_end_value ?? ""}
            />
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Loan term</FormLabel>
            <Select disabled value={data?.loan_term?.toString() ?? ""}>
              <SelectTrigger className="text-main h-12 w-full text-sm">
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(LOAN_TERMS).map(([k, v]) => (
                  <SelectItem key={k} value={v.key.toString()}>
                    {v.text}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem className="flex flex-col">
            <FormLabel className="text-main text-sm font-normal">
              When do you require the loan by? <span className="text-default italic">required</span>
            </FormLabel>
            <FormControl>
              <div className="relative">
                <Input
                  disabled
                  className="cursor-pointer"
                  value={data?.loan_by ? format(data.loan_by, "dd/MM/yyyy") : ""}
                />
                <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                  <IconCalendar className="text-default text-lg" />
                </div>
              </div>
            </FormControl>
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">
              What is your exit strategy?
            </FormLabel>
            <Select disabled value={data?.exit_strategy}>
              <SelectTrigger className="text-main h-12 w-full text-sm">
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(EXIT_STRATEGIES).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="hidden flex-1 md:block"></div>
      </div>
    </div>
  )
}
