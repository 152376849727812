export const SITE_TITLE = "FundsConnect"

/* ---------------------------------------------------------------- */

export const ALL = "all"

export const YN = {
  Yes: "Yes",
  No: "No"
}

export const TITLES = {
  Mr: "Mr",
  Mrs: "Mrs",
  Miss: "Miss",
  Ms: "Ms",
  Dr: "Dr",
  Other: "Other"
}

export const GENDERS = {
  Male: "Male",
  Female: "Female",
  NonBinary: "Non-Binary"
}

export const MARITAL_STATUSES = {
  Single: "Single",
  Married: "Married",
  Defacto: "De facto",
  Divorced: "Divorced",
  Widowed: "Widowed",
  Other: "Other"
}

export const RESIDENTIAL_STATUSES = {
  Owner: "Owner",
  Buying: "Buying",
  Boarding: "Boarding",
  Renting: "Renting",
  WithParents: "With Parents"
}

/* ---------------------------------------------------------------- */

export const CRON_JOB_TYPES = {
  BUSINESS_USER_MEMBERSHIP: "Business user's Membership",
  BUSINESS_USER_INSURANCE: "Business user's Insurance",
  BUSINESS_SETTING_MEMBERSHIP: "Business setting's Membership",
  BUSINESS_SETTING_ACL: "Business setting's Australian Credit Licence (ACL)",
  BUSINESS_SETTING_PII: "Business setting's Professional Indemnity Insurance"
}

/* ---------------------------------------------------------------- */

export const NOTIFICATION_TYPES = {
  APPLICATION: "Application",
  IntroducerLead: "Introducer Lead"
}

export const APPLICATION_STEPS = {
  BorrowerSelection: "Borrower Selection",
  CommissionSplit: "Commission Split",
  // ApplicantType: "Applicant Type",
  FundingSolution: "Funding Solution",
  LoanType: "Loan Type",
  LoanDetails: "Loan Details",
  SecurityDetails: "Security Details",
  LenderSelection: "Lender Selection",
  EntityAL: "Entity's A & L",
  IndividualAL: "Individual's A & L",
  SupportingDocuments: "Supporting Documents",
  ReviewApplication: "Review Application"
}

export const USER_ROLES = {
  Employee: "employee",
  Broker: "broker",
  Introducer: "introducer",
  Enterprise: "enterprise",
  Administrator: "administrator"
}

export const BUSINESS_ROLE = {
  Manager: "manager",
  Employee: "employee"
}

export const ENTITY_TYPES = {
  Company: {
    key: "Company",
    description:
      "This is just for Company text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#7459BB",
      background: "#7459BB26",
      graph: "#7459BB"
    }
  },
  Trust: {
    key: "Trust (With Corporate Trustee Only)",
    description:
      "This is just for Trust text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#5FCB6A",
      background: "#5FCB6A26",
      graph: "#5FCB6A"
    }
  },
  SMSF: {
    key: "SMSF (With Corporate Trustee Only) ",
    description:
      "This is just for SMSF text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#4B8CEE",
      background: "#4B8CEE26",
      graph: "#4B8CEE"
    }
  },

  Partnership: {
    key: "Partnership",
    description:
      "This is just for Partnership text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#52DAE3",
      background: "#52DAE326",
      graph: "#52DAE3"
    }
  },
  SoleTrader: {
    key: "Sole Trader",
    description:
      "This is just for Sole Trader text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#D366E4",
      background: "#D366E426",
      graph: "#D366E4"
    }
  },

  Individual: {
    key: "Individual",
    description:
      "This is just for Individual text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#48E3A4",
      background: "#48E3A426",
      graph: "#48E3A4"
    }
  }
}

export const TRUST_TYPES = {
  Family: {
    key: "Family"
  },
  Discretionary: {
    key: "Discretionary"
  },
  Testamentary: {
    key: "Testamentary"
  },
  UnitTrust: {
    key: "Unit Trust"
  },
  Other: {
    key: "Other"
  }
}

export const MAX_BORROWER_INDIVIDUAL_COUNT = 100

export const BORROWER_INDIVIDUAL_ROLES = {
  Director: {
    key: "director",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Guarantor: {
    key: "guarantor",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Shareholder: {
    key: "shareholder",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Beneficiary: {
    key: "beneficiary",
    entity_types: [ENTITY_TYPES.Trust.key]
  },
  Member: {
    key: "member",
    entity_types: [ENTITY_TYPES.SMSF.key]
  }
}

/* ---------------------------------------------------------------- */

export const USER_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  ARCHIVED: 2
}

export const LENDER_STATUS = {
  ACTIVE: 1,
  ARCHIVED: 2
}

export const APPLICATION_STATUS = {
  Incomplete: {
    key: "Incomplete",
    color: {
      text: "#F59E0B",
      background: "#F59E0B26"
    }
  },
  Received: {
    key: "Received",
    color: {
      text: "#379FFF",
      background: "#379FFF26"
    }
  },
  Credit: {
    key: "Credit",
    color: {
      text: "#8BCCEE",
      background: "#8BCCEE26"
    }
  },
  FurtherInformationRequestedFromCredit: {
    key: "Further Information Requested from Credit",
    color: {
      text: "#000000",
      background: "#00000026"
    }
  },
  Approved: {
    key: "Approved",
    color: {
      text: "#F59E0B",
      background: "#F59E0B26"
    }
  },
  Declined: {
    key: "Declined",
    color: {
      text: "#EF4444",
      background: "#EF444426"
    }
  },
  Withdrawn: {
    key: "Withdrawn",
    color: {
      text: "#483CD0",
      background: "#483CD026"
    }
  },
  Settled: {
    key: "Settled",
    color: {
      text: "#10B981",
      background: "#10B98126"
    }
  },
  Indicative: {
    key: "Indicative",
    color: {
      text: "#E5B24E",
      background: "#E5B24E26"
    }
  },
  IndicativeSigned: {
    key: "Indicative Signed",
    color: {
      text: "#E5B24E",
      background: "#E5B24E26"
    }
  },
  Formal: {
    key: "Formal",
    color: {
      text: "#C10BC5",
      background: "#C10BC526"
    }
  },
  FormalSigned: {
    key: "Formal Signed",
    color: {
      text: "#C10BC5",
      background: "#C10BC526"
    }
  }
}

/* ---------------------------------------------------------------- */

export const AGGREGATORS = {
  LoanMarketGroup: {
    key: "Loan Market Group (LMG)"
  },
  AustralianFinanceGroup: {
    key: "Australian Finance Group (AFG)"
  },
  Finsure: {
    key: "Finsure"
  },
  Connective: {
    key: "Connective"
  },
  SpecialistFinanceGroup: {
    key: "Specialist Finance Group (SFG)"
  },
  VowFinancial: {
    key: "Vow Financial"
  },
  NationalMortgageBrokers: {
    key: "National Mortgage Brokers (nMB)"
  },
  OutsourceFinancial: {
    key: "Outsource Financial"
  },
  MoneyQuest: {
    key: "MoneyQuest"
  },
  Other: {
    key: "Other"
  }
}

export const ENTITY_INDUSTRIES = [
  "Mining and Resources",
  "Agriculture",
  "Finance and Insurance",
  "Healthcare and Social Assistance",
  "Education",
  "Tourism",
  "Construction",
  "Manufacturing",
  "Technology and Innovation",
  "Retail Trade"
]

export const FUNDING_SOLUTIONS = {
  BusinessFinance: {
    key: "Business Finance",
    description:
      "This is just for Business Finance text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  ConstructionFinance: {
    key: "Construction Finance",
    description:
      "This is just for Construction Finance text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  DevelopmentFinance: {
    key: "Development Finance",
    description:
      "This is just for Development Finance text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  RuralFinance: {
    key: "Rural Finance",
    description:
      "This is just for Rural Finance text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  // SMSFFinance: {
  //   key: "SMSF Finance",
  //   description:
  //     "This is just for SMSF Finance text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  // },
  CorporateFinance: {
    key: "Corporate Finance",
    description:
      "This is just for Corporate Finance text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  // IndustrySectorLending: {
  //   key: "Industry Sector Lending",
  //   description:
  //     "This is just for Industry Sector Lending text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  // },
  PropertyFinance: {
    key: "Property Finance",
    description:
      "This is just for Property Finance text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  }
}

/* ---------------------------------------------------------------- */

export const LOAN_TYPES = {
  SeniorDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Senior Debt",
    description:
      "This is just for Senior Debt text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  StretchedSeniorDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Stretched Senior Debt",
    description:
      "This is just for Stretched Senior Debt text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  MezzanineDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Mezzanine Debt",
    description:
      "This is just for Mezzanine Debt text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  PreferredEquity: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Preferred Equity",
    description:
      "This is just for Preferred Equity text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  },
  JointVenture: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Joint Venture",
    description:
      "This is just for Joint Venture text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet."
  }
}

/* ---------------------------------------------------------------- */

export const LOAN_DOCUMENT_TYPES = {
  FullDoc: {
    key: "Full Doc",
    description:
      "This is just for Full Doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  LowDoc: {
    key: "Low Doc",
    description:
      "This is just for Low Doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  AltDoc: {
    key: "Alt Doc",
    description:
      "This is just for Alt Doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  LeaseDoc: {
    key: "Lease Doc",
    description:
      "This is just for Lease Doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  PrivateCredit: {
    key: "Private Credit",
    description:
      "This is just for Private Credit text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: true
  }
}

export const EXIT_STRATEGIES = {
  Refinance: "Refinance",
  SaleOfAsset: "Sale of Asset",
  Other: "Other"
}

export const SPECIALISED_DEVELOPMENTS = {
  AffordableSocialHousing: "Affordable & Social Housing",
  AgedCare: "Aged Care",
  BrR: "BrR - Build-to-Rent",
  Childcare: "Childcare",
  DataCentres: "Data Centres",
  DisabilityHousing: "Disability Housing",
  Education: "Education",
  Healthcare: "Healthcare",
  HotelAccommodation: "Hotel (Accommodation)",
  HospitalityVenue: "Hospitality Venue",
  Infrastructure: "Infrastructure",
  LandLeaseCommunities: "Land Lease Communities",
  LifeSciences: "Life Sciences",
  LuxuryHousing: "Luxury Housing",
  NDISHousing: "NDIS Housing",
  Retirement: "Retirement",
  SDAHousing: "SDA Housing",
  SelfStorage: "Self Storage",
  StudentAccommodation: "Student Accommodation"
}

export const NON_SPECIALISED_DEVELOPMENTS = {
  CommercialDevelopment: "Commercial Development",
  ResidentialDevelopment: "Residential Development",
  IndustrialDevelopment: "Industrial Development",
  RetailDevelopment: "Retail Development",
  MixedUseDevelopment: "Mixed-Use Development"
}

export const LOAN_TERMS = {
  M6: {
    key: 6,
    text: "6 months"
  },
  M12: {
    key: 12,
    text: "12 months"
  },
  M18: {
    key: 18,
    text: "18 months"
  },
  M24: {
    key: 24,
    text: "24 months"
  },
  M30: {
    key: 30,
    text: "30 months"
  },
  M36: {
    key: 36,
    text: "36 months"
  }
}

export const REPAYMENTS = {
  CapitalisedInterest: "Capitalised Interest (Into the Facility)",
  InterestOnly: "Interest Only (Paid Monthly)",
  PrepaidInterest: "Prepaid Interest (From Funds Advanced)"
}

export const REGIONS = {
  CapitalCities: "Capital Cities",
  Metropolitan: "Metropolitan - within 15 km Radius of Capital City Centre",
  MajorRegional: "Major Regional - Population > 20,000"
}

export const COUNTRIES = {
  Australia: "Australia",
  NewZealand: "New Zealand"
}

export const AUSTRALIA_STATES = [
  "New South Wales",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia",
  "Australian Capital Territory",
  "Northern Territory"
]

export const NEWZEALAND_STATES = ["North Island", "South Island"]

/* ---------------------------------------------------------------- */

export const CHECK_ITEM_TYPES = {
  YesNo: "Yes/No",
  Input: "Input",
  Upload: "Upload"
}

/* ---------------------------------------------------------------- */

export const APPLICATION_ASSET_TYPES = {
  Entity: "entity",
  Individual: "individual"
}

export const APPLICATION_LIABILITY_TYPES = {
  Entity: "entity",
  Individual: "individual"
}

export const ASSETS_TYPES = {
  RealEstate: "Real Estate",
  Cash: "Cash",
  Stocks: "Stocks",
  Bonds: "Bonds",
  Vehicles: "Vehicles",
  Jewelry: "Jewelry",
  Livestock: "Livestock"
}

export const LIABILITIES_TYPES = {
  Mortgage: "Mortgage",
  Loans: "Loans",
  Overdrafts: "Overdrafts",
  Alimony: "Alimony"
}

/* ---------------------------------------------------------------- */

export const MEMBERSHIP_ASSOCIATIONS = ["CAFBA", "FBAA", "MFAA", "Other"]

/* ---------------------------------------------------------------- */

export const BANK_BRANCHES = [
  "Commonwealth Bank of Australia (CBA)",
  "Westpac Banking Corporation",
  "National Australia Bank (NAB)",
  "Australia and New Zealand Banking Group (ANZ)",
  "Bendigo and Adelaide Bank",
  "Macquarie Bank",
  "St. George Bank",
  "Bank of Queensland",
  "Suncorp Bank",
  "Other"
]

/* ---------------------------------------------------------------- */

export const DOCUMENT_TYPES = {
  Primary: "primary",
  Secondary: "secondary"
}

export const DOCUMENTS = [
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Foreign Passport (current)",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Australian Passport (current or expired within last 2 years but not cancelled)",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Australian Citizenship Certificate",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Full Birth certificate (not birth certificate extract)",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Certificate of Identity issued by the Australian Government to refugees and non Australian citizens forentry to Australia",
    points: 70
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Australian Driver Licence/Learner’s Permit",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Current (Australian) Tertiary Student Identification Card",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "AustraliaPhoto identification card issued for Australian regulatory purposes (e.g. Aviation/Maritime Security identification, security industry etc.)",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Government employee ID (Australian Federal/State/Territory)",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Primary,
    name: "Defence Force Identity Card (with photo or signature)",
    points: 40
  },

  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Department of Veterans Affairs (DVA) card",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Centrelink card (with reference number)",
    points: 40
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Birth Certificate Extract",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Birth card (NSW Births, Deaths, Marriages issue only)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Medicare card",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Credit card or account card",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Australian Marriage certificate (Australian Registry issue only)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Decree Nisi / Decree Absolute (Australian Registry issue only)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Change of name certificate (Australian Registry issue only)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Bank statement (showing transactions)",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Property lease agreement - current address",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Taxation assessment notice",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Australian Mortgage Documents - Current address",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Rating Authority - Current address eg Land Rates",
    points: 25
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Utility Bill - electricity, gas, telephone - Current address (less than 12 months old)",
    points: 20
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Reference from Indigenous Organisation",
    points: 20
  },
  {
    type: DOCUMENT_TYPES.Secondary,
    name: "Documents issued outside Australia (equivalent to Australian documents). Must have official translation attachedForeign",
    points: 20
  }
]

/* ---------------------------------------------------------------- */

export const PRIVY_USER_LEVEL = {
  Bronze: "bronze",
  Silver: "silver",
  Gold: "gold"
}

export const PRIVY_STATUS = {
  Pending: "pending",
  WaitingVerification: "waiting_verification",
  Verified: "verified",
  Rejected: "rejected"
}

/* ---------------------------------------------------------------- */

export const abnEntity2fcEntity = (abnEntity: string) => {
  const entityMap: Record<string, any> = {
    "Approved Deposit Fund": null,
    "APRA Regulated Non-Public Offer Fund": null,
    "APRA Regulated Public Offer Fund": null,
    "APRA Regulated Fund (Fund Type Unknown)": null,
    "ATO Regulated Self-Managed Superannuation Fund": ENTITY_TYPES.Company.key,
    "Australian Private Company": ENTITY_TYPES.Company.key,
    "Australian Public Company": ENTITY_TYPES.Company.key,
    "Cash Management Trust": null,
    "Commonwealth Government APRA Regulated Public Sector Fund": null,
    "Commonwealth Government APRA Regulated Public Sector Scheme": null,
    "Commonwealth Government Cash Management Trust": null,
    "Commonwealth Government Company": null,
    "Commonwealth Government Co-operative": null,
    "Commonwealth Government Corporate Unit Trust": null,
    "Commonwealth Government Discretionary Investment Trust": null,
    "Commonwealth Government Discretionary Services Management Trust": null,
    "Commonwealth Government Discretionary Trading Trust": null,
    "Commonwealth Government Entity": null,
    "Commonwealth Government Fixed Trust": null,
    "Commonwealth Government Fixed Unit Trust": null,
    "Commonwealth Government Hybrid Trust": null,
    "Commonwealth Government Limited Partnership": null,
    "Commonwealth Government Listed Public Unit Trust": null,
    "Commonwealth Government Non-Regulated Super Fund": null,
    "Commonwealth Government Other Incorporated Entity": null,
    "Commonwealth Government Other Unincorporated Entity": null,
    "Commonwealth Government Partnership": null,
    "Commonwealth Government Pooled Development Fund": null,
    "Commonwealth Government Private Company": null,
    "Commonwealth Government Public Company": null,
    "Commonwealth Government Public Trading Trust": null,
    "Commonwealth Government Statutory Authority": null,
    "Commonwealth Government Strata Title": null,
    "Commonwealth Government Super Fund": null,
    "Commonwealth Government Trust": null,
    "Commonwealth Government Unlisted Public Unit Trust": null,
    // "Co-operative": ENTITY_TYPES.CoOperative.key,
    "Co-operative": null,
    "Corporate Collective Investment Vehicle (CCIV) Sub-Fund": null,
    "Corporate Unit Trust": ENTITY_TYPES.Trust.key,
    "Deceased Estate": null,
    "Diplomatic/Consulate Body or High Commission": null,
    "Discretionary Investment Trust": ENTITY_TYPES.Trust.key,
    "Discretionary Services Management Trust": ENTITY_TYPES.Trust.key,
    "Discretionary Trading Trust": ENTITY_TYPES.Trust.key,
    "Family Partnership": ENTITY_TYPES.Partnership.key,
    "First Home Saver Accounts (FHSA) Trust": null,
    "Fixed Trust": ENTITY_TYPES.Trust.key,
    "Fixed Unit Trust": ENTITY_TYPES.Trust.key,
    "Hybrid Trust": ENTITY_TYPES.Trust.key,
    "Individual/Sole Trader": ENTITY_TYPES.Individual.key,
    "Limited Partnership": ENTITY_TYPES.Partnership.key,
    "Listed Public Unit Trust": ENTITY_TYPES.Trust.key,
    "Local Government APRA Regulated Public Sector Fund": null,
    "Local Government APRA Regulated Public Sector Scheme": null,
    "Local Government Cash Management Trust": null,
    "Local Government Company": null,
    "Local Government Co-operative": null,
    "Local Government Corporate Unit Trust": null,
    "Local Government Discretionary Investment Trust": null,
    "Local Government Discretionary Services Management Trust": null,
    "Local Government Discretionary Trading Trust": null,
    "Local Government Entity": null,
    "Local Government Fixed Trust": null,
    "Local Government Fixed Unit Trust": null,
    "Local Government Hybrid Trust": null,
    "Local Government Limited Partnership": null,
    "Local Government Listed Public Unit Trust": null,
    "Local Government Non-Regulated Super Fund": null,
    "Local Government Other Incorporated Entity": null,
    "Local Government Other Unincorporated Entity": null,
    "Local Government Partnership": null,
    "Local Government Pooled Development Fund": null,
    "Local Government Private Company": null,
    "Local Government Public Company": null,
    "Local Government Public Trading Trust": null,
    "Local Government Statutory Authority": null,
    "Local Government Strata Title": null,
    "Local Government Trust": null,
    "Local Government Unlisted Public Unit Trust": null,
    "Non-Regulated Superannuation Fund": ENTITY_TYPES.Company.key,
    "Other Incorporated Entity": null,
    "Other Partnership": ENTITY_TYPES.Partnership.key,
    "Other Trust": ENTITY_TYPES.Trust.key,
    // "Other Unincorporated Entity": ENTITY_TYPES.Association.key,
    "Other Unincorporated Entity": null,
    "Pooled Development Fund": null,
    "Pooled Superannuation Trust": null,
    "Public Trading Trust": ENTITY_TYPES.Trust.key,
    "Small APRA Fund": ENTITY_TYPES.Company.key,
    "State Government APRA Regulated Public Sector Scheme": null,
    "State Government Cash Management Trust": null,
    "State Government Company": null,
    "State Government Co-operative": null,
    "State Government Corporate Unit Trust": null,
    "State Government Discretionary Investment Trust": null,
    "State Government Discretionary Services Management Trust": null,
    "State Government Discretionary Trading Trust": null,
    "State Government Entity": null,
    "State Government Fixed Trust": null,
    "State Government Fixed Unit Trust": null,
    "State Government Hybrid Trust": null,
    "State Government Limited Partnership": null,
    "State Government Listed Public Unit Trust": null,
    "State Government Non-Regulated Super Fund": null,
    "State Government Other Incorporated Entity": null,
    "State Government Other Unincorporated Entity": null,
    "State Government Partnership": null,
    "State Government Pooled Development Fund": null,
    "State Government Private Company": null,
    "State Government Public Company": null,
    "State Government Public Trading Trust": null,
    "State Government Statutory Authority": null,
    "State Government Strata Title": null,
    "State Government Trust": null,
    "State Government Unlisted Public Unit Trust": null,
    "State Government APRA Regulated Public Sector Fund": null,
    "Strata-title": null,
    "Super fund": ENTITY_TYPES.Company.key,
    "Territory Government APRA Regulated Public Sector Fund": null,
    "Territory Government APRA Regulated Public Sector Scheme": null,
    "Territory Government Cash Management Trust": null,
    "Territory Government Co-operative": null,
    "Territory Government Corporate Unit Trust": null,
    "Territory Government Discretionary Investment Trust": null,
    "Territory Government Discretionary Services Management Trust": null,
    "Territory Government Discretionary Trading Trust": null,
    "Territory Government Entity": null,
    "Territory Government Fixed Trust": null,
    "Territory Government Fixed Unit Trust": null,
    "Territory Government Hybrid Trust": null,
    "Territory Government Limited Partnership": null,
    "Territory Government Listed Public Unit Trust": null,
    "Territory Government Non-Regulated Super Fund": null,
    "Territory Government Other Incorporated Entity": null,
    "Territory Government Other Unincorporated Entity": null,
    "Territory Government Partnership": null,
    "Territory Government Pooled Development Fund": null,
    "Territory Government Private Company": null,
    "Territory Government Public Company": null,
    "Territory Government Public Trading Trust": null,
    "Territory Government Statutory Authority": null,
    "Territory Government Strata Title": null,
    "Territory Government Trust": null,
    "Territory Government Unlisted Public Unit Trust": null,
    "Unlisted Public Unit Trust": ENTITY_TYPES.Trust.key
  }

  return entityMap[abnEntity]
}
