import React, { useEffect, useState } from "react"

import clsx from "clsx"
import { format } from "date-fns"
import { useFormContext } from "react-hook-form"

import { FileUpload } from "@/components/_uiext"
import { IconCalendar, IconDocument } from "@/components/_icons"

import { Calendar } from "@repo/ui/components/ui/calendar"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { Progress } from "@repo/ui/components/ui/progress"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"

import {
  AUSTRALIA_STATES,
  DOCUMENTS,
  ENTITY_INDUSTRIES,
  ENTITY_TYPES,
  MEMBERSHIP_ASSOCIATIONS
} from "@repo/util/constant"

interface Props {
  businessDocuments: any[]
}

export default function BusinessSetting({ businessDocuments }: Readonly<Props>) {
  const form = useFormContext()

  const [openMembershipCalendar, setOpenMembershipCalendar] = useState(false)
  const [openAclCalendar, setOpenAclCalendar] = useState(false)
  const [openPiiCalendar, setOpenPiiCalendar] = useState(false)

  const [documents, setDocuments] = useState<any[]>([])
  const [totalScore, setTotalScore] = useState(1)
  const [currentScore, setCurrentScore] = useState(0)

  const initialize = () => {
    setDocuments(businessDocuments)

    let nTotalScore = 0,
      nCurrentScore = 0

    for (let doc of DOCUMENTS) {
      nTotalScore += doc.points
    }

    for (let d of businessDocuments) {
      nCurrentScore += DOCUMENTS.filter((doc) => doc.name === d.name)?.[0].points ?? 0
    }

    setTotalScore(nTotalScore)
    setCurrentScore(nCurrentScore)
  }

  useEffect(() => {
    initialize()
  }, [businessDocuments])

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-main py-4 text-xl font-semibold">Business Setting Information</h2>

      <h3 className="text-default mb-4 py-4 text-lg font-semibold">Entity Details</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_type"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Entity type</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                      <SelectItem key={k} value={v.key}>
                        {v.key}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="registered_business_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Registered business name
                </FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="australian_business_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Australian business number (ABN)
                </FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <div
            className={clsx(
              "",
              form.watch("entity_type") === "Company" || form.watch("entity_type") === "Trust"
                ? "block"
                : "hidden"
            )}
          >
            <FormField
              control={form.control}
              name="australian_company_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Australian company number (ACN)
                  </FormLabel>
                  <Input autoComplete="off" {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Entity email address
                </FormLabel>
                <Input type="email" autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_contact_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Entity contact number
                </FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="website"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Website</FormLabel>
                <Input type="url" autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_industry"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Entity industry</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {ENTITY_INDUSTRIES.map((ei) => (
                      <SelectItem key={ei} value={ei}>
                        {ei}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <h3 className="text-default mb-4 mt-5 py-4 text-lg font-semibold">Address</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="street_address"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Street address</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="suburb"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {AUSTRALIA_STATES.map((as) => (
                      <SelectItem key={as} value={as}>
                        {as}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="postcode"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <h3 className="text-default mb-4 mt-5 py-4 text-lg font-semibold">Membership</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="membership_association"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Association</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {MEMBERSHIP_ASSOCIATIONS.map((ma) => (
                      <SelectItem key={ma} value={ma}>
                        {ma}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="hidden flex-1 md:block"></div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="membership_certificate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Membership Certificate
                </FormLabel>
                <FileUpload {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="membership_expiry"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="text-main mb-1 text-sm font-normal">Expiry date</FormLabel>
                <Popover open={openMembershipCalendar} onOpenChange={setOpenMembershipCalendar}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className="cursor-pointer"
                          readOnly
                          value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                        />
                        <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                          <IconCalendar className="text-default text-lg" />
                        </div>
                      </div>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      captionLayout="dropdown-buttons"
                      fromYear={new Date().getFullYear() - 10}
                      toYear={new Date().getFullYear() + 10}
                      mode="single"
                      selected={field.value}
                      defaultMonth={field.value}
                      onSelect={(date) => {
                        field.onChange(date)
                        setOpenMembershipCalendar(false)
                      }}
                      disabled={(date) => date < new Date("1900-01-01")}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <h3 className="text-default mb-4 mt-5 py-4 text-lg font-semibold">
        Australian Credit Licence (ACL)
      </h3>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="acl_certificate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Australian Credit Licence
                </FormLabel>
                <FileUpload {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="acl_expiry"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="text-main mb-1 text-sm font-normal">Expiry date</FormLabel>
                <Popover open={openAclCalendar} onOpenChange={setOpenAclCalendar}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className="cursor-pointer"
                          readOnly
                          value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                        />
                        <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                          <IconCalendar className="text-default text-lg" />
                        </div>
                      </div>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      captionLayout="dropdown-buttons"
                      fromYear={new Date().getFullYear() - 10}
                      toYear={new Date().getFullYear() + 10}
                      mode="single"
                      selected={field.value}
                      defaultMonth={field.value}
                      onSelect={(date) => {
                        field.onChange(date)
                        setOpenAclCalendar(false)
                      }}
                      disabled={(date) => date < new Date("1900-01-01")}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <h3 className="text-default mb-4 mt-5 py-4 text-lg font-semibold">
        Professional Indemnity Insurance
      </h3>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="pii_certificate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Certificate of Currency
                </FormLabel>
                <FileUpload {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="pii_expiry"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="text-main mb-1 text-sm font-normal">Expiry date</FormLabel>
                <Popover open={openPiiCalendar} onOpenChange={setOpenPiiCalendar}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className="cursor-pointer"
                          readOnly
                          value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                        />
                        <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                          <IconCalendar className="text-default text-lg" />
                        </div>
                      </div>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      captionLayout="dropdown-buttons"
                      fromYear={new Date().getFullYear() - 10}
                      toYear={new Date().getFullYear() + 10}
                      mode="single"
                      selected={field.value}
                      defaultMonth={field.value}
                      onSelect={(date) => {
                        field.onChange(date)
                        setOpenPiiCalendar(false)
                      }}
                      disabled={(date) => date < new Date("1900-01-01")}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <h3 className="text-default mb-4 mt-5 py-4 text-lg font-semibold">100 Points of ID</h3>

      <div className="mb-6 flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
        <p className="text-main text-sm">Identification Documents</p>

        <div className="flex flex-col items-center justify-between gap-2.5 md:flex-row">
          <div className="flex w-full items-center justify-between gap-2.5 md:w-auto">
            <p className="text-main text-sm">{Math.round((currentScore * 100) / totalScore)}%</p>
            <Progress
              className="h-2 w-full md:w-[100px]"
              value={Math.round((currentScore * 100) / totalScore)}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        {documents.map((d) => (
          <div key={d.name} className="flex items-center gap-4 rounded-2xl border p-4">
            <div className="bg-primary flex min-h-12 min-w-12 items-center justify-center rounded-full">
              <IconDocument className="text-xl text-white" />
            </div>

            <p className="text-main">{d.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
