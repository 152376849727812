import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { InView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import { Button } from "@repo/ui/components/ui/button"
import { Form } from "@repo/ui/components/ui/form"
import { APPLICATION_STEPS } from "@repo/util/constant"

import BorrowerSummary from "../_components/summary/BorrowerSummary"
import CommissionSplitSummary from "../_components/summary/CommissionSplitSummary"
import FundingSolutionSummary from "../_components/summary/FundingSolutionSummary"
import LoanTypeSummary from "../_components/summary/LoanTypeSummary"
import LoanDetailsSummary from "../_components/summary/LoanDetailsSummary"
import SecurityDetailsSummary from "../_components/summary/SecurityDetailsSummary"
import LenderSelectionSummary from "../_components/summary/LenderSelectionSummary"
import EntityALSummary from "../_components/summary/EntityALSummary"
import IndividualALSummary from "../_components/summary/IndividualALSummary"
import SupportingDocumentsSummary from "../_components/summary/SupportingDocumentsSummary"

import SubmitDialog from "../_components/SubmitDialog"

import { LOADING_TIMEOUT } from "../util"
import { useAppContext } from "../AppContext"

const FormSchema = z.object({})

type FormSchemaType = z.infer<typeof FormSchema>

export default function ReviewApplication() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAppContext()
  const navigate = useNavigate()
  const store = useApplicationDraftStore()

  const [openSuccess, setOpenSuccess] = useState(false)
  const [inviews, setInviews] = useState<boolean[]>([])
  const [summaryItems, setSummaryItems] = useState([
    {
      step: APPLICATION_STEPS.BorrowerSelection,
      threshold: 0.1,
      component: <BorrowerSummary />
    },
    {
      step: APPLICATION_STEPS.CommissionSplit,
      threshold: 0.1,
      component: <CommissionSplitSummary />
    },
    {
      step: APPLICATION_STEPS.FundingSolution,
      threshold: 0.1,
      component: <FundingSolutionSummary />
    },
    {
      step: APPLICATION_STEPS.LoanType,
      threshold: 0.1,
      component: <LoanTypeSummary />
    },
    {
      step: APPLICATION_STEPS.LoanDetails,
      threshold: 0.1,
      component: <LoanDetailsSummary />
    },
    {
      step: APPLICATION_STEPS.SecurityDetails,
      threshold: 0.1,
      component: <SecurityDetailsSummary />
    },
    {
      step: APPLICATION_STEPS.LenderSelection,
      threshold: 0.1,
      component: <LenderSelectionSummary />
    },
    {
      step: APPLICATION_STEPS.EntityAL,
      threshold: 0.01,
      component: <EntityALSummary />
    },
    {
      step: APPLICATION_STEPS.IndividualAL,
      threshold: 0.01,
      component: <IndividualALSummary />
    },
    {
      step: APPLICATION_STEPS.SupportingDocuments,
      threshold: 0.01,
      component: <SupportingDocumentsSummary />
    }
  ])

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {}
  })

  const initialize = () => {
    let updatedValues = Array(steps.length - 1).fill(false)
    updatedValues[0] = true
    setInviews(updatedValues)
  }

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const jumpStep = (step: number) => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          current_step: steps[step]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep(step)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = () => {
    store.submitAction(data.uuid, () => {
      setOpenSuccess(true)
    })
  }

  useEffect(() => {
    initialize()
  }, [data])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-8">
            <h3 className="text-main mb-2 text-lg font-semibold">Application Summary</h3>

            <p className="text-default mb-6 text-sm font-normal">
              Ensure your application details are correct before submission.
            </p>

            {summaryItems.map((item: any, index: number) => (
              <InView
                key={item.step}
                className="mb-8"
                threshold={item.threshold}
                onChange={(inView, entry) => {
                  setInviews((prev) => {
                    const updatedValues = JSON.parse(JSON.stringify(prev))
                    updatedValues[index] = inView
                    return updatedValues
                  })
                }}
                id={steps[index]}
              >
                <div className="mb-6 flex flex-col gap-2.5 md:flex-row md:items-center md:justify-between">
                  <h3 className="text-main text-lg font-semibold">{steps[index]}</h3>

                  <Button
                    type="button"
                    className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
                    onClick={() => {
                      jumpStep(index)
                    }}
                  >
                    Edit
                  </Button>
                </div>

                {item.component}
              </InView>
            ))}
          </div>

          <StickyShortcut
            items={steps.slice(0, -1)?.map((s: string, index: number) => ({
              id: s,
              label: s,
              inView: inviews[index]
            }))}
          />
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.loading}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Submit
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>

        <SubmitDialog open={openSuccess} setOpen={setOpenSuccess} />
      </form>
    </Form>
  )
}
