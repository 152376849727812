import React from "react"

import { Text, View } from "@react-pdf/renderer"
import { format } from "date-fns"
import numeral from "numeral"

interface Props {
  data: Record<string, any>
}

export default function ContentLoanDetails(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column" }} wrap={true}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 14,
          borderBottom: "1px solid #CDCDCD"
        }}
      >
        5. Loan Details
      </Text>

      <View
        style={{
          paddingLeft: 32,
          paddingVertical: 16,
          display: "flex",
          flexDirection: "column",
          gap: 8
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Loan amount
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {numeral(data?.loan_amount).format("$ 0,0[.]00")}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Project end value
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {numeral(data?.project_end_value).format("$ 0,0[.]00")}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Loan term
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.loan_term} months
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            When do you require the loan by?
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {format(data?.loan_by, "dd/MM/yyyy")}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            What is your exit strategy?
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.exit_strategy}
          </Text>
        </View>
      </View>
    </View>
  )
}
