import React from "react"

import { Navigate, RouteObject } from "react-router-dom"

import { USER_ROLES } from "@repo/util/constant"

import UnauthenticatedRoute from "./components/_routes/UnauthenticatedRoute"
import AuthenticatedRoute from "./components/_routes/AuthenticatedRoute"
import RoleBasedRoute from "./components/_routes/RoleBasedRoute"

import Home from "./pages/home"

import Login from "./pages/auth/login"
import Verify from "./pages/auth/verify"

import SignUp from "./pages/auth/signup"
import EmailVerify from "./pages/auth/email-verify"

import PasswordReset from "./pages/auth/password-reset"
import SetNewPassword from "./pages/auth/set-new-password"

import AcceptInvitation from "./pages/auth/accept-invitation"

import AccountSettings from "./pages/account-settings"
import Dashboard from "./pages/dashboard"

import ApplicationsList from "./pages/applications/list"
import ApplicationsCreate from "./pages/applications/create"
import ApplicationsEdit from "./pages/applications/edit"
import ApplicationsView from "./pages/applications/view"
import ApplicationsInvites from "./pages/applications/invites"
import ApplicationsConsents from "./pages/applications/consents"

import BorrowersList from "./pages/borrowers/list"
import BorrowersAdd from "./pages/borrowers/add"
import BorrowersEdit from "./pages/borrowers/edit"
import BorrowersView from "./pages/borrowers/view"

import LeadsList from "./pages/leads/list"
import LeadsView from "./pages/leads/view"

import BusinessUsersList from "./pages/business/users/list"
import BusinessUsersAdd from "./pages/business/users/add"
import BusinessUsersEdit from "./pages/business/users/edit"
import BusinessUsersView from "./pages/business/users/view"
// import BusinessBank from "./pages/business/bank"
import BusinessSettings from "./pages/business/settings"

import AdminDashboard from "./pages/admin/dashboard"

import AdminApplicationsList from "./pages/admin/applications/list"
import AdminApplicationsCreate from "./pages/admin/applications/create"
import AdminApplicationsEdit from "./pages/admin/applications/edit"
import AdminApplicationsView from "./pages/admin/applications/view"

import AdminBorrowersList from "./pages/admin/borrowers/list"
import AdminBorrowersAdd from "./pages/admin/borrowers/add"
import AdminBorrowersEdit from "./pages/admin/borrowers/edit"
import AdminBorrowersView from "./pages/admin/borrowers/view"

import AdminUsersList from "./pages/admin/users/list"
import AdminUsersEdit from "./pages/admin/users/edit"
import AdminUsersView from "./pages/admin/users/view"

import AdminLendersList from "./pages/admin/lenders/list"
import AdminLendersAdd from "./pages/admin/lenders/add"
import AdminLendersEdit from "./pages/admin/lenders/edit"
import AdminLendersView from "./pages/admin/lenders/view"

import AdminLeadsList from "./pages/admin/leads/list"
import AdminLeadsView from "./pages/admin/leads/view"

const router: RouteObject[] = [
  {
    path: "/",
    element: <Home />
  },

  /**
   * ----------------------------------------------------------------
   * Unauthenticated Routes
   * ----------------------------------------------------------------
   */
  {
    path: "login",
    element: <Login />
  },
  {
    path: "verify",
    element: <Verify />
  },

  {
    path: "password-reset",
    element: (
      <UnauthenticatedRoute>
        <PasswordReset />
      </UnauthenticatedRoute>
    )
  },
  {
    path: "set-new-password/:token",
    element: (
      <UnauthenticatedRoute>
        <SetNewPassword />
      </UnauthenticatedRoute>
    )
  },

  {
    path: "signup",
    element: (
      <UnauthenticatedRoute>
        <SignUp />
      </UnauthenticatedRoute>
    )
  },
  {
    path: "email-verify/:token",
    element: (
      <UnauthenticatedRoute>
        <EmailVerify />
      </UnauthenticatedRoute>
    )
  },

  {
    path: "accept-invitation/:token",
    element: (
      <UnauthenticatedRoute>
        <AcceptInvitation />
      </UnauthenticatedRoute>
    )
  },

  /**
   * ----------------------------------------------------------------
   * User - Common Routes
   * ----------------------------------------------------------------
   */
  {
    path: "account-settings",
    element: (
      <AuthenticatedRoute>
        <AccountSettings />
      </AuthenticatedRoute>
    )
  },
  {
    path: "dashboard",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute
          roles={[
            USER_ROLES.Introducer,
            USER_ROLES.Broker,
            USER_ROLES.Enterprise,
            USER_ROLES.Employee
          ]}
        >
          <Dashboard />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  {
    path: "applications/create",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee]}>
          <ApplicationsCreate />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "applications/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee]}>
          <ApplicationsList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "applications/edit/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee]}>
          <ApplicationsEdit />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "applications/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee]}>
          <ApplicationsView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "applications/invites/:uuid",
    element: (
      <UnauthenticatedRoute>
        <ApplicationsInvites />
      </UnauthenticatedRoute>
    )
  },
  {
    path: "applications/consents/:uuid",
    element: (
      <UnauthenticatedRoute>
        <ApplicationsConsents />
      </UnauthenticatedRoute>
    )
  },

  {
    path: "borrowers/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee]}>
          <BorrowersList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "borrowers/add",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee]}>
          <BorrowersAdd />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "borrowers/edit/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee]}>
          <BorrowersEdit />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "borrowers/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise, USER_ROLES.Employee]}>
          <BorrowersView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  /**
   * ----------------------------------------------------------------
   * Leads - Introducer
   * ----------------------------------------------------------------
   */
  {
    path: "leads/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Introducer]}>
          <LeadsList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "leads/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Introducer]}>
          <LeadsView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  /**
   * ----------------------------------------------------------------
   * User - Enterprise Routes
   * ----------------------------------------------------------------
   */
  {
    path: "business/users/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Enterprise]}>
          <BusinessUsersList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "business/users/add",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Enterprise]}>
          <BusinessUsersAdd />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "business/users/edit/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Enterprise]}>
          <BusinessUsersEdit />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "business/users/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Enterprise]}>
          <BusinessUsersView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  // {
  //   path: "business/bank",
  //   element: (
  //     <AuthenticatedRoute>
  //       <RoleBasedRoute roles={[USER_ROLES.Broker, USER_ROLES.Enterprise]}>
  //         <BusinessBank />
  //       </RoleBasedRoute>
  //     </AuthenticatedRoute>
  //   )
  // },

  {
    path: "business/settings",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Introducer, USER_ROLES.Broker, USER_ROLES.Enterprise]}>
          <BusinessSettings />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  /**
   * ----------------------------------------------------------------
   * Admin Routes
   * ----------------------------------------------------------------
   */

  {
    path: "admin/dashboard",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminDashboard />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  {
    path: "admin/applications/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminApplicationsList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/applications/create",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminApplicationsCreate />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/applications/edit/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminApplicationsEdit />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/applications/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminApplicationsView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  {
    path: "admin/borrowers/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminBorrowersList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/borrowers/add",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminBorrowersAdd />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/borrowers/edit/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminBorrowersEdit />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/borrowers/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminBorrowersView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  {
    path: "admin/users/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminUsersList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/users/edit/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminUsersEdit />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/users/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminUsersView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  {
    path: "admin/lenders/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminLendersList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/lenders/add",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminLendersAdd />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/lenders/edit/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminLendersEdit />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/lenders/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminLendersView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  {
    path: "admin/leads/list",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminLeadsList />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },
  {
    path: "admin/leads/view/:uuid",
    element: (
      <AuthenticatedRoute>
        <RoleBasedRoute roles={[USER_ROLES.Administrator]}>
          <AdminLeadsView />
        </RoleBasedRoute>
      </AuthenticatedRoute>
    )
  },

  /**
   * ----------------------------------------------------------------
   * Fallback routes
   * ----------------------------------------------------------------
   */
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]

export default router
