import React, { useEffect, useState } from "react"

import clsx from "clsx"
import { FormProvider, useForm, useFormContext } from "react-hook-form"

import { DocumentUpload, LoadingButton } from "@/components/_uiext"
import useBusinessDocumentStore from "@/stores/useBusinessDocumentStore"

import { Button } from "@repo/ui/components/ui/button"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "@repo/ui/components/ui/dialog"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Progress } from "@repo/ui/components/ui/progress"
import { ScrollArea } from "@repo/ui/components/ui/scroll-area"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { DOCUMENTS, DOCUMENT_TYPES } from "@repo/util/constant"
import { IconDocument } from "@/components/_icons"

interface DocumentItemProps {
  isChecked: boolean
  type: string
  name: string
  points: number
  value: any
}
function DocumentItem(props: Readonly<DocumentItemProps>) {
  const form = useFormContext()
  const { isChecked, name, points, value } = props

  const [checked, setChecked] = useState(isChecked)

  useEffect(() => {
    form.setValue(`is_${name}`, isChecked)
  }, [isChecked])

  useEffect(() => {
    form.setValue(`${btoa(encodeURIComponent(name))}`, value)
  }, [value])

  return (
    <div className="flex min-h-14 flex-col gap-2 p-1 md:flex-row md:items-center md:justify-between">
      <div className="flex flex-1 items-center justify-between gap-2">
        <FormField
          control={form.control}
          name={`is_${name}`}
          render={({ field }) => (
            <FormItem className="flex items-center space-x-2 space-y-0">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked)
                    setChecked(checked as boolean)
                  }}
                  className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                />
              </FormControl>
              <FormLabel className="text-main font-normal">{name}</FormLabel>
            </FormItem>
          )}
        />

        <p className={clsx("min-w-[68px] text-sm", checked ? "text-main" : "text-default")}>
          {points} points
        </p>
      </div>

      <div className={clsx(checked ? "w-full md:w-40" : "hidden")}>
        <FormField
          control={form.control}
          name={`${btoa(encodeURIComponent(name))}`}
          render={({ field }) => (
            <FormItem>
              <DocumentUpload {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}

interface Props {
  documents: any[]
  totalScore: number
  currentScore: number
}

function IdentificationDocuments(props: Readonly<Props>) {
  const { documents, totalScore, currentScore } = props

  const [open, setOpen] = useState(false)
  const store = useBusinessDocumentStore()
  const [type, setType] = useState(DOCUMENT_TYPES.Primary)
  const form = useForm({})

  const onSubmit = (values: any) => {
    store.updateAction(values, () => {
      setOpen(false)
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button type="button" className="h-auto w-full px-6 py-4 text-sm md:w-auto">
          Identify
        </Button>
      </DialogTrigger>

      <DialogContent className="w-full bg-white p-5 md:min-w-[600px] md:p-10">
        <FormProvider {...form}>
          <form className="flex flex-col gap-0" onSubmit={form.handleSubmit(onSubmit)}>
            <h3 className="text-main mb-4 text-center text-lg font-semibold">
              Identification Documents
            </h3>

            <p className="mb-5 px-10 text-center text-base">
              Tick the box and upload the correct documentation to gain 100 points of ID.
            </p>

            <div className="mb-5 flex items-center justify-center gap-2 px-0 md:px-16">
              <p className="text-main text-sm">{currentScore}</p>
              <Progress
                className="h-2 w-full"
                value={Math.round((currentScore * 100) / totalScore)}
              />
            </div>

            <div className="mb-6 flex items-center justify-center md:hidden">
              <Select value={type} onValueChange={(value) => setType(value)}>
                <SelectTrigger className="h-auto rounded-full border-[#868194] bg-transparent bg-white px-4 py-1 text-sm">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={DOCUMENT_TYPES.Primary}>Primary Documents</SelectItem>
                    <SelectItem value={DOCUMENT_TYPES.Secondary}>Secondary Documents</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <div className="mb-6 hidden items-center justify-between gap-2 px-16 md:flex">
              <Button
                type="button"
                className={clsx(
                  type === DOCUMENT_TYPES.Primary
                    ? "border-primary w-full rounded-full border font-normal"
                    : "text-default w-full rounded-full border border-[#868194] bg-transparent font-normal hover:bg-transparent"
                )}
                onClick={() => setType(DOCUMENT_TYPES.Primary)}
              >
                Primary Documents
              </Button>
              <Button
                type="button"
                className={clsx(
                  type === DOCUMENT_TYPES.Secondary
                    ? "border-primary w-full rounded-full border font-normal"
                    : "text-default w-full rounded-full border border-[#868194] bg-transparent font-normal hover:bg-transparent"
                )}
                onClick={() => setType(DOCUMENT_TYPES.Secondary)}
              >
                Secondary Documents
              </Button>
            </div>

            <ScrollArea className="mb-6 h-80 w-full pr-2">
              <div
                className={clsx(
                  "flex flex-col gap-2",
                  type === DOCUMENT_TYPES.Primary ? "block" : "hidden"
                )}
              >
                {DOCUMENTS.filter((d) => d.type === DOCUMENT_TYPES.Primary).map((d) => (
                  <DocumentItem
                    key={d.name}
                    isChecked={documents.filter((doc) => doc.name === d.name).length > 0}
                    value={documents.filter((doc) => doc.name === d.name)?.[0]?.file ?? null}
                    {...d}
                  />
                ))}
              </div>

              <div
                className={clsx(
                  "flex flex-col gap-2",
                  type === DOCUMENT_TYPES.Secondary ? "block" : "hidden"
                )}
              >
                {DOCUMENTS.filter((d) => d.type === DOCUMENT_TYPES.Secondary).map((d) => (
                  <DocumentItem
                    key={d.name}
                    isChecked={documents.filter((doc) => doc.name === d.name).length > 0}
                    value={documents.filter((doc) => doc.name === d.name)?.[0]?.file ?? null}
                    {...d}
                  />
                ))}
              </div>
            </ScrollArea>

            <div className="flex items-center justify-center gap-2">
              <DialogClose asChild>
                <Button
                  type="button"
                  className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
                >
                  Cancel
                </Button>
              </DialogClose>

              <LoadingButton
                loading={store.loading}
                type="submit"
                className="border-primary border px-6 py-4 md:!w-auto"
              >
                Save
              </LoadingButton>
            </div>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

export default function Points() {
  const store = useBusinessDocumentStore()

  const [documents, setDocuments] = useState<any[]>([])
  const [totalScore, setTotalScore] = useState(1)
  const [currentScore, setCurrentScore] = useState(0)

  const initialize = () => {
    store.getAction((data) => {
      setDocuments(data)

      let nTotalScore = 0,
        nCurrentScore = 0

      for (let doc of DOCUMENTS) {
        nTotalScore += doc.points
      }

      for (let d of data) {
        nCurrentScore += DOCUMENTS.filter((doc) => doc.name === d.name)?.[0].points ?? 0
      }

      setTotalScore(nTotalScore)
      setCurrentScore(nCurrentScore)
    })
  }

  useEffect(() => {
    initialize()
  }, [store.reloadRequired])

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">100 Points of ID</h3>

      <div className="mb-6 flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
        <p className="text-main text-sm">Identification Documents</p>

        <div className="flex flex-col items-center justify-between gap-2.5 md:flex-row">
          <div className="flex w-full items-center justify-between gap-2.5 md:w-auto">
            <p className="text-main text-sm">{currentScore}</p>
            <Progress
              className="h-2 w-full md:w-[100px]"
              value={Math.round((currentScore * 100) / totalScore)}
            />
          </div>

          <IdentificationDocuments
            documents={documents ?? []}
            totalScore={totalScore}
            currentScore={currentScore}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6">
        {documents?.map((d) => (
          <div key={d.name} className="flex items-center gap-4 rounded-2xl border p-4">
            <div className="bg-primary flex min-h-12 min-w-12 items-center justify-center rounded-full">
              <IconDocument className="text-xl text-white" />
            </div>

            <p className="text-main">{d.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
