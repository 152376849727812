import React, { useState } from "react"

import { InputMask } from "@react-input/mask"
import { APIProvider } from "@vis.gl/react-google-maps"
import clsx from "clsx"
import { getNames } from "country-list"
import { format } from "date-fns"
import pluralize from "pluralize"
import { useFieldArray, useFormContext } from "react-hook-form"
import { toast } from "sonner"

import { IconCalendar } from "@/components/_icons"
import { PlacesAutoPredict } from "@/components/_uiext"
import { extractPlace } from "@/utils/google"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Button } from "@repo/ui/components/ui/button"
import { Calendar } from "@repo/ui/components/ui/calendar"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  BORROWER_INDIVIDUAL_ROLES,
  GENDERS,
  MARITAL_STATUSES,
  MAX_BORROWER_INDIVIDUAL_COUNT,
  RESIDENTIAL_STATUSES,
  TITLES
} from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import IndividualRoleQuestions from "./IndividualRoleQuestions"

interface Props {
  isView?: boolean
  fieldName: string
}

export default function IndividualDetails(props: Readonly<Props>) {
  const { isView = false, fieldName } = props
  const form = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: fieldName
  })

  const [openAccordion, setOpenAccordion] = useState<string[]>([`${fieldName}-0`])
  const [openCalendars, setOpenCalendars] = useState<boolean[]>(
    [...Array(MAX_BORROWER_INDIVIDUAL_COUNT).keys()].map((x) => false)
  )
  const [openExpiries, setOpenExpiries] = useState<boolean[]>(
    [...Array(MAX_BORROWER_INDIVIDUAL_COUNT).keys()].map((x) => false)
  )

  const handleAddClick = () => {
    if (fields.length < MAX_BORROWER_INDIVIDUAL_COUNT) {
      append({
        role: "",
        title: "",
        gender: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        marital_status: "",
        birth_date: undefined,
        birth_country: "",
        birth_place: "",

        residential_street_address: "",
        residential_suburb: "",
        residential_state: "",
        residential_postcode: "",
        residential_years: undefined,
        residential_months: undefined,

        previous_street_address: "",
        previous_suburb: "",
        previous_state: "",
        previous_postcode: "",

        is_australian_resident: false,
        current_residential_status: "",
        postal_address: "",
        email: "",
        mobile_number: "",

        drivers_license_number: "",
        card_number: "",
        expiry_date: undefined,

        rq_shareholder_is_beneficial_owner: false,
        rq_shareholder_is_exposed_person: false,
        rq_director_is_exposed_person: false,
        rq_beneficiary_is_exposed_person: false
      })

      setOpenAccordion((prev: string[]) => [...prev, `${fieldName}-${fields.length}`])
    } else {
      toast.error(CLIENT_MSG.BORROWER_INDIVIDUALS_LIMITED)
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-main py-4 text-lg font-semibold">Individual Details</h3>

        {!isView && (
          <Button
            type="button"
            className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleAddClick}
          >
            Add
          </Button>
        )}
      </div>

      <Accordion
        type="multiple"
        className="w-full"
        value={openAccordion}
        onValueChange={setOpenAccordion}
      >
        {fields.map((item, index) => (
          <AccordionItem
            className="mb-4 rounded-2xl border p-6"
            key={item.id}
            value={`${fieldName}-${index}`}
          >
            <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
              <div className="flex flex-wrap items-center gap-4">
                <div
                  className={clsx(
                    "flex min-h-12 min-w-12 items-center justify-center rounded-full border",
                    openAccordion.includes(`${fieldName}-${index}`)
                      ? "border-primary text-primary"
                      : "border-[#CDCDCD] text-[#CDCDCD]"
                  )}
                >
                  {index + 1}
                </div>
                <div className="text-main text-lg font-semibold">Individual Details</div>

                {!isView && (
                  <span
                    className="bg-destructive flex items-center gap-2 rounded-xl px-6 py-4 text-sm text-white"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </span>
                )}
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col px-1 pt-6">
              <h4 className="text-main mb-4 text-lg font-semibold">Key Information</h4>
              <div
                className={clsx(
                  "mb-4 w-full",
                  Object.entries(BORROWER_INDIVIDUAL_ROLES).filter((bir) =>
                    bir[1].entity_types.includes(form.watch("entity_type"))
                  )?.length > 0
                    ? ""
                    : "hidden"
                )}
              >
                <FormField
                  control={form.control}
                  name={`${fieldName}.${index}.role`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Role</FormLabel>
                      <FormControl>
                        <div className="flex flex-wrap items-center gap-2.5">
                          {Object.entries(BORROWER_INDIVIDUAL_ROLES).map(([k, v]) => {
                            if (!v.entity_types.includes(form.watch("entity_type"))) return null

                            return (
                              <Button
                                key={k}
                                disabled={isView}
                                type="button"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  field.value === v.key
                                    ? "border-primary"
                                    : "text-default border-[#868194] bg-transparent hover:bg-transparent"
                                )}
                                onClick={() => field.onChange(v.key)}
                              >
                                {v.key}
                              </Button>
                            )
                          })}
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.title`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Title</FormLabel>
                        <Select
                          disabled={isView}
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.entries(TITLES).map(([k, v]) => (
                              <SelectItem key={k} value={v}>
                                {v}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.gender`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Gender</FormLabel>
                        <Select
                          disabled={isView}
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.entries(GENDERS).map(([k, v]) => (
                              <SelectItem key={k} value={v}>
                                {v}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.first_name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.middle_name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Middle name</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.last_name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.marital_status`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Marital Status
                        </FormLabel>
                        <Select
                          disabled={isView}
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.entries(MARITAL_STATUSES).map(([k, v]) => (
                              <SelectItem key={k} value={v}>
                                {v}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.birth_date`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-main mb-1 text-sm font-normal">
                          Birth date
                        </FormLabel>
                        <Popover
                          open={openCalendars[index]}
                          onOpenChange={(open) => {
                            if (!isView) {
                              setOpenCalendars((prev) => {
                                let updated = [...prev]
                                updated[index] = open
                                return updated
                              })
                            }
                          }}
                        >
                          <PopoverTrigger asChild>
                            <FormControl>
                              <div className="relative">
                                <Input
                                  readOnly
                                  disabled={isView}
                                  className="cursor-pointer"
                                  value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                                />
                                <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                                  <IconCalendar className="text-default text-lg" />
                                </div>
                              </div>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              initialFocus
                              captionLayout="dropdown-buttons"
                              fromYear={1900}
                              toYear={new Date().getFullYear()}
                              mode="single"
                              disabled={(date) =>
                                date < new Date("1900-01-01") || date > new Date()
                              }
                              selected={field.value}
                              defaultMonth={field.value}
                              onSelect={(date) => {
                                field.onChange(date)
                                setOpenCalendars((prev) => {
                                  let updated = [...prev]
                                  updated[index] = false
                                  return updated
                                })
                              }}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.birth_country`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-main text-sm font-normal">
                          Country of birth
                        </FormLabel>
                        <Select
                          disabled={isView}
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {getNames()
                              .sort((a, b) => a.localeCompare(b))
                              .map((as) => (
                                <SelectItem key={as} value={as}>
                                  {as}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.birth_place`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Place of birth
                        </FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <h4 className="text-main my-4 text-base font-semibold">Residential Address</h4>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.residential_street_address`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Street address
                        </FormLabel>
                        <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                          <PlacesAutoPredict
                            disabled={isView}
                            textValue={field.value}
                            onPlaceSelect={(
                              address_components: Array<Record<string, any>> | null
                            ) => {
                              if (address_components) {
                                const placeInfo = extractPlace(address_components)

                                form.setValue(
                                  `${fieldName}.${index}.residential_street_address`,
                                  placeInfo.street
                                )
                                form.setValue(
                                  `${fieldName}.${index}.residential_suburb`,
                                  placeInfo.suburb
                                )
                                form.setValue(
                                  `${fieldName}.${index}.residential_state`,
                                  placeInfo.state?.long_name
                                )
                                form.setValue(
                                  `${fieldName}.${index}.residential_postcode`,
                                  placeInfo.postcode
                                )
                              }
                            }}
                          />
                        </APIProvider>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.residential_suburb`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.residential_state`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.residential_postcode`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.residential_years`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-main text-sm font-normal">
                          Residential years
                        </FormLabel>
                        <Select
                          disabled={isView}
                          onValueChange={(v) => field.onChange(Number(v))}
                          value={field.value?.toString()}
                        >
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {Array.from({ length: 50 }, (_, i) => i + 1).map((ry) => (
                              <SelectItem key={ry} value={ry.toString()}>
                                {ry} {pluralize("year", ry)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.residential_months`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-main text-sm font-normal">
                          Residential months
                        </FormLabel>
                        <Select
                          disabled={isView}
                          onValueChange={(v) => field.onChange(Number(v))}
                          value={field.value?.toString()}
                        >
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {Array.from({ length: 12 }, (_, i) => i + 1).map((rm) => (
                              <SelectItem key={rm} value={rm.toString()}>
                                {rm} {pluralize("month", rm)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <h4 className="text-main my-4 text-base font-semibold">
                Previous Address (if less than 2 years at current address)
              </h4>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.previous_street_address`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Street address
                        </FormLabel>
                        <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                          <PlacesAutoPredict
                            disabled={isView}
                            textValue={field.value}
                            onPlaceSelect={(
                              address_components: Array<Record<string, any>> | null
                            ) => {
                              if (address_components) {
                                const placeInfo = extractPlace(address_components)

                                form.setValue(
                                  `${fieldName}.${index}.previous_street_address`,
                                  placeInfo.street
                                )
                                form.setValue(
                                  `${fieldName}.${index}.previous_suburb`,
                                  placeInfo.suburb
                                )
                                form.setValue(
                                  `${fieldName}.${index}.previous_state`,
                                  placeInfo.state?.long_name
                                )
                                form.setValue(
                                  `${fieldName}.${index}.previous_postcode`,
                                  placeInfo.postcode
                                )
                              }
                            }}
                          />
                        </APIProvider>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.previous_suburb`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.previous_state`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.previous_postcode`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:items-center md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.is_australian_resident`}
                    render={({ field }) => (
                      <FormItem className="flex items-start space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled={isView}
                            checked={field.value}
                            onCheckedChange={(checked) => {
                              field.onChange(checked)
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <div className="flex flex-col">
                          <FormLabel className="text-main text-sm font-normal">
                            Australian resident
                          </FormLabel>
                          <FormMessage />
                        </div>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.current_residential_status`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Current residential Status
                        </FormLabel>
                        <Select
                          disabled={isView}
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.entries(RESIDENTIAL_STATUSES).map(([k, v]) => (
                              <SelectItem key={k} value={v}>
                                {v}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.postal_address`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Postal address
                        </FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="hidden flex-1 md:block" />
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.email`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Email address
                        </FormLabel>
                        <Input disabled={isView} type="email" autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.mobile_number`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Mobile number
                        </FormLabel>
                        <InputMask
                          showMask
                          disabled={isView}
                          component={Input}
                          mask="04__ ___ ___"
                          replacement={{ _: /\d/ }}
                          ref={field.ref}
                          name={field.name}
                          value={field.value || "04__ ___ ___"}
                          onChange={field.onChange}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.drivers_license_number`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Drivers license number
                        </FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.card_number`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Card number</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.expiry_date`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-main mb-1 text-sm font-normal">
                          Expiry date
                        </FormLabel>
                        <Popover
                          open={openExpiries[index]}
                          onOpenChange={(open) => {
                            if (!isView) {
                              setOpenExpiries((prev) => {
                                let updated = [...prev]
                                updated[index] = open
                                return updated
                              })
                            }
                          }}
                        >
                          <PopoverTrigger asChild>
                            <FormControl>
                              <div className="relative">
                                <Input
                                  readOnly
                                  disabled={isView}
                                  className="cursor-pointer"
                                  value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                                />
                                <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                                  <IconCalendar className="text-default text-lg" />
                                </div>
                              </div>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              initialFocus
                              captionLayout="dropdown-buttons"
                              fromYear={1900}
                              toYear={new Date().getFullYear() + 10}
                              mode="single"
                              disabled={(date) => date < new Date("1900-01-01")}
                              selected={field.value}
                              defaultMonth={field.value}
                              onSelect={(date) => {
                                field.onChange(date)
                                setOpenExpiries((prev) => {
                                  let updated = [...prev]
                                  updated[index] = false
                                  return updated
                                })
                              }}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="hidden flex-1 md:block" />
              </div>

              <IndividualRoleQuestions isView={isView} fieldName={fieldName} index={index} />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
