import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { InputMask } from "@react-input/mask"
import { motion } from "framer-motion"
import json2mq from "json2mq"
import { useForm } from "react-hook-form"
import { LuEye, LuEyeOff } from "react-icons/lu"
import { useMediaQuery } from "react-responsive"
import { Link } from "react-router-dom"
import * as z from "zod"

import Logo from "@/components/_layout/components/Logo"
import { LoadingButton } from "@/components/_uiext"
import useSignupStore from "@/stores/useSignupStore"
import { ANIMATION_DURATION } from "@/utils/constants"

import { Form, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"

import StageIndicator from "./components/StageIndicator"

const FormSchema = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  mobile_number: z.string().min(1, {
    message: "Please enter a valid mobile number"
  }),
  email: z.string().email({
    message: "Please enter a valid email address"
  }),
  password: z.string().min(8, {
    message: "Password should be at least 8 characters"
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function Step02() {
  const store = useSignupStore()
  const [showPassword, setShowPassword] = useState(false)

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const variants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * ANIMATION_DURATION,
        duration: ANIMATION_DURATION
      }
    })
  }

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      first_name: store.data?.first_name ?? "",
      last_name: store.data?.last_name ?? "",
      mobile_number: store.data?.mobile_number ?? "",
      email: store.data?.email ?? "",
      password: store.data?.password ?? ""
    }
  })

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev)
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateData({ ...values })
    store.registerAction(() => {
      store.goNextStage()
    })
  }

  return (
    <div className="flex h-full w-full flex-col gap-8">
      <motion.div
        custom={0}
        initial="initial"
        animate="animate"
        variants={variants}
        className="flex flex-wrap items-center justify-center gap-4 md:justify-between"
      >
        <Logo />

        <p className="hidden text-sm font-normal md:block">
          Already have an account?{" "}
          <Link className="text-main font-medium" to="/login">
            Log in
          </Link>
        </p>
      </motion.div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-1 flex-col justify-center"
        >
          <motion.h3
            custom={1}
            initial="initial"
            animate="animate"
            variants={variants}
            className="text-main mb-2.5 text-lg font-semibold"
          >
            You're almost there!
          </motion.h3>

          <motion.p
            custom={2}
            initial="initial"
            animate="animate"
            variants={variants}
            className="mb-8 text-sm font-normal"
          >
            We just need a few more simple details from you.
          </motion.p>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-2.5">
            <motion.div
              custom={3}
              initial="initial"
              animate="animate"
              variants={variants}
              className="flex-1"
            >
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
                    <Input autoFocus={!isMobile} autoComplete="off" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </motion.div>

            <motion.div
              custom={4}
              initial="initial"
              animate="animate"
              variants={variants}
              className="flex-1"
            >
              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
                    <Input autoComplete="off" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </motion.div>
          </div>

          <motion.div
            custom={5}
            initial="initial"
            animate="animate"
            variants={variants}
            className="mb-4 w-full"
          >
            <FormField
              control={form.control}
              name="mobile_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Mobile number</FormLabel>
                  <InputMask
                    showMask
                    component={Input}
                    mask="04__ ___ ___"
                    replacement={{ _: /\d/ }}
                    ref={field.ref}
                    name={field.name}
                    value={field.value || "04__ ___ ___"}
                    onChange={field.onChange}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </motion.div>

          <motion.div
            custom={6}
            initial="initial"
            animate="animate"
            variants={variants}
            className="mb-4 w-full"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
                  <Input autoComplete="off" type="email" {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
          </motion.div>

          <motion.div
            custom={7}
            initial="initial"
            animate="animate"
            variants={variants}
            className="mb-6 w-full"
          >
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Password</FormLabel>
                  <div className="relative w-full">
                    <Input
                      autoComplete="off"
                      type={showPassword ? "text" : "password"}
                      {...field}
                    />

                    <div
                      className="absolute right-[4px] top-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <LuEyeOff className="text-[16px]" />
                      ) : (
                        <LuEye className="text-[16px]" />
                      )}
                    </div>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </motion.div>

          <motion.div
            custom={8}
            initial="initial"
            animate="animate"
            variants={variants}
            className="w-full"
          >
            <LoadingButton type="submit" loading={store.loading}>
              Get started
            </LoadingButton>
          </motion.div>
        </form>
      </Form>

      <div className="flex flex-col">
        <motion.p
          custom={9}
          initial="initial"
          animate="animate"
          variants={variants}
          className="mb-4 block text-center text-sm font-normal md:hidden"
        >
          Already have an account?{" "}
          <Link className="text-main font-medium" to="/login">
            Log in
          </Link>
        </motion.p>

        <motion.div custom={10} initial="initial" animate="animate" variants={variants}>
          <StageIndicator />
        </motion.div>
      </div>
    </div>
  )
}
