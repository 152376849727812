import React from "react"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

import Default from "./Default"
import ConstructionFinance from "./ConstructionFinance"

import { useAdminAppContext } from "../../AdminAppContext"

export default function SecurityDetails() {
  const { data } = useAdminAppContext()

  if (data.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key)
    return <ConstructionFinance />

  return <Default />
}
