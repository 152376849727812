import React from "react"

import { ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"

import { IconArchive, IconEdit, IconMore, IconView } from "@/components/_icons"
import useAdminBorrowerStore from "@/stores/useAdminBorrowerStore"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { Button } from "@repo/ui/components/ui/button"
import { ENTITY_TYPES } from "@repo/util/constant"

interface ActionColumnProps {
  row: any
}

function ActionColumn({ row }: Readonly<ActionColumnProps>) {
  const navigate = useNavigate()
  const store = useAdminBorrowerStore()

  const handleViewClick = (uuid: string) => () => {
    navigate(`/admin/borrowers/view/${uuid}`)
  }

  const handleEditClick = (uuid: string) => () => {
    navigate(`/admin/borrowers/edit/${uuid}`)
  }

  const handleArchiveClick = (uuid: string) => () => {
    store.archiveAction(uuid)
  }

  return (
    <div className="text-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <IconMore className="text-2xl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-32" align="end">
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleViewClick(row.original.uuid)}
          >
            <IconView className="text-base" /> View
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleEditClick(row.original.uuid)}
          >
            <IconEdit className="text-base" />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleArchiveClick(row.original.uuid)}
          >
            <IconArchive className="text-base" />
            {!row.original.is_archived ? "Archive" : "Restore"}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "belongs_to",
    header: () => <>Belongs To</>,
    cell: ({ row }) => (
      <div className="text-sm">
        {row.original.holder.first_name} {row.original.holder.last_name}
      </div>
    )
  },
  {
    accessorKey: "entity_name",
    header: () => <>Entity Name</>,
    cell: ({ row }) => <div className="text-sm">{row.original.entity_name}</div>
  },
  {
    accessorKey: "entity_type",
    header: () => <>Entity Type</>,
    cell: ({ row }) => (
      <div
        className="rounded-sm px-6 py-1.5 text-center capitalize"
        style={{
          color: Object.entries(ENTITY_TYPES)
            .map(([k, v]) => ({ ...v }))
            .filter((as) => as.key === row.original.entity_type)?.[0].color.text,
          backgroundColor: Object.entries(ENTITY_TYPES)
            .map(([k, v]) => ({ ...v }))
            .filter((as) => as.key === row.original.entity_type)?.[0].color.background
        }}
      >
        {row.original.entity_type}
      </div>
    )
  },
  {
    accessorKey: "abn",
    header: () => <>ABN</>,
    cell: ({ row }) => <div className="whitespace-nowrap text-sm">{row.original.abn}</div>
  },
  {
    accessorKey: "contact",
    header: () => <>Contact</>,
    cell: ({ row }) => (
      <div className="text-sm">
        {row.original.individuals?.[0]?.first_name} {row.original.individuals?.[0]?.middle_name}{" "}
        {row.original.individuals?.[0]?.last_name}
      </div>
    )
  },
  {
    accessorKey: "email",
    header: () => <>Email Address</>,
    cell: ({ row }) => <div className="text-sm">{row.original.individuals?.[0]?.email}</div>
  },
  {
    accessorKey: "mobile_number",
    header: () => <>Mobile Number</>,
    cell: ({ row }) => <div className="text-sm">{row.original.individuals?.[0]?.mobile_number}</div>
  },
  {
    accessorKey: "actions",
    header: () => <div className="text-center">Actions</div>,
    cell: ({ row }) => <ActionColumn row={row} />
  }
]

export default columns
